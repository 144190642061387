


































































import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";

interface StepError {
  step: number;
  error: string;
}

export default defineComponent({
  setup(_, { root }) {
    const state = reactive({
      loading: false,
      success: false,
      error: false,
      empty: false,
      table: false,
      loaded: false,
    });

    const model = reactive<{
      data: any;
    }>({
      data: {},
    });

    //  Start fetch single fee group data

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`partner/group/${root.$route.params.pgid}`)
        .then(({ data: { partnerGroup } }) => {
          model.data.name = partnerGroup.name;
          model.data.numberOfPartnersInRow = partnerGroup.numberOfPartnersInRow;
          model.data.state = partnerGroup.state;
          model.data.priority = partnerGroup.priority;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);

    //  End fetch single fee group data

    // Start update item

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        name: model.data.name,
        state: model.data.status,
        numberOfPartnersInRow: model.data.numberOfPartnersInRow,
        priority: model.data.priority,
      };

      state.loading = true;

      axiosInstance
        .put(`/partner/group/${root.$route.params.pgid}`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          model.data = {};
        })
        .catch((error) => {
          state.error = error.response.status;
        })
        .finally(() => (state.loading = false));
    };

    // End update item

    const numberOfPartnersInRow = [1, 2, 3, 4];

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return "Brak uprawnień";
        case 404:
          return "Endpointu nie znaleziono";
        case 409:
          return "Grupa już istnieje";
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return "Wystąpił bład";
      }
    };

    return {
      state,
      model,
      onSubmit,
      getErrorMessage,
      numberOfPartnersInRow,
    };
  },
});
